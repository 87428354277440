import { AXIOS_ALERT, LOADING, LOADING_LOCATION, SET_BASE_URL } from "../constants"

const init = {
    loading: false,
    isLoading: {

    }
}
const axiosReducer = (state=init, action)=>{
    switch (action.type){
        
        case SET_BASE_URL:
            return {
                ...state,
                url: action.url
            }
        
        case AXIOS_ALERT:
            return {
                ...state,
                ...action.payload
            }

        case LOADING:
            return {
                ...state,
                loading: action.payload
            }
        
        case LOADING_LOCATION:
            return {
                ...state,
                isLoading: {
                    ...state.isLoading, ...action.payload
                }
            }
        default: 
            return state
    }

}

export default axiosReducer
