import { SEND_SOCKET, SET_SCHEME, SET_SETTINGS } from "../constants"
import { DefaultStorage } from "../storage";


export const setSetting = (key, value, persist=false)=>{
    let payload = {};
    payload[key] = value;


    return {
        type: SET_SCHEME,
        payload,
        persist
    }
}

export const loadSettings = ()=>{

    return async dispatch=>{

        let payload = await DefaultStorage.getItem("SETTINGS");

        if(!payload) return;

        payload = JSON.parse(payload);

        dispatch({
            type: SET_SETTINGS,
            payload
        })
    }
}

export const addDeviceLng = (lng)=>{

    return dispatch =>{

        dispatch({
            type: SEND_SOCKET,
            payload:{
                name: "SOCKET",
                message:{
                    type: "SET_LNG",
                    payload:{
                        lng
                    }
                }
            }
        })
    }

}