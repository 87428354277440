import _ from "lodash";
import { ADD_NOTIFICATION, DELETE_NOTIFICATION } from "../constants";



const notifyReducer = (state={contents: []}, action)=>{

    switch(action.type){

        case ADD_NOTIFICATION:
            return {
                ...state,
                contents: [...state.contents, action.payload]
            }
        
        case DELETE_NOTIFICATION:
            let c = [...state.contents];
            _.remove(c, action.payload);

            return {
                ...state,
                contents: c
            }

        default:
            return state;
    }

}

export default notifyReducer;
