import { CLEAR_ERRORS, SET_ERRORS, SHOW_MESSAGE } from "../constants"


export const setErrors = (errors)=>{
    console.log(errors)
    return {
        type: SET_ERRORS,
        value: errors
    }
}

export const clearErrors = ()=>{
    return {
        type: CLEAR_ERRORS
    }
}

export const showMessage = (payload)=>{

    return {
        type: SHOW_MESSAGE,
        payload
    }
}