import _ from "lodash";
import { ADD_NOTIFICATION, DELETE_NOTIFICATION } from "../constants";


export const addNotification = ({id, setId, ...kwargs}, timeout)=>{

    return (dispatch, getState)=>{

        const {contents} = getState().notify;
        let payload = {...kwargs};

        if(id && _.includes(contents, {id})){
            return 
        }

        if(!id){
            do {
                id = _.random(0, 1024);
            } while (_.includes(contents, {id}));
            
            setId?.(id);
        }

        payload.id = id 

        dispatch({
            type: ADD_NOTIFICATION,
            payload
        })

        if(timeout)
            setTimeout(()=>dispatch(closeNotification(id)), timeout)
    }
}

export const closeNotification = (id)=>({
    type: DELETE_NOTIFICATION,
    payload:{
        id
    }
})
