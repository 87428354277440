import _ from "lodash";


const freader = (tg, keys=[], tgKeys={})=>{
    let values = {}

    for (let i = 0; i < keys.length; i++) {
        let k = keys[i], tk = tgKeys[k];
        
        tk = tk !== undefined? tk: _.snakeCase(k);

        try{

            values[tk] = tg[k].value;
        }catch{
            console.error("could not read key ", k)
            console.log(tg["message"])
        }
        
    }

    return values;

}

export default freader;